@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  margin: 0;
  font-family: 'BBS Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'BBS Bold';
  src: url('../public/fonts/bt-beau-sans/bt-beau-sans-bold.woff') format('woff'),
    url('../public/fonts/bt-beau-sans/bt-beausans-bold.woff2') format('woff2'),
    url('../public/fonts/bt-beau-sans/bt-beausans-bold.eot') format('eot'),
    url('../public/fonts/bt-beau-sans/bt-beausans-bold.svg') format('svg'),
    url('../public/fonts/bt-beau-sans/BT-BeauSans-Bold.ttf') format('ttf');
}
@font-face {
  font-family: 'BBS Medium';
  src: url('../public/fonts/bt-beau-sans/bt-beau-sans-medium.woff') format('woff'),
    url('../public/fonts/bt-beau-sans/bt-beausans-medium.woff2') format('woff2'),
    url('../public/fonts/bt-beau-sans/bt-beausans-medium.eot') format('eot'),
    url('../public/fonts/bt-beau-sans/bt-beausans-medium.svg') format('svg'),
    url('../public/fonts/bt-beau-sans/BT-BeauSans-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'BBS Regular';
  src: url('../public/fonts/bt-beau-sans/bt-beau-sans-regular.woff') format('woff'),
    url('../public/fonts/bt-beau-sans/bt-beausans-regular.woff2') format('woff2'),
    url('../public/fonts/bt-beau-sans/bt-beausans-regular.eot') format('eot'),
    url('../public/fonts/bt-beau-sans/bt-beausans-regular.svg') format('svg'),
    url('../public/fonts/bt-beau-sans/BT-BeauSans-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'BBS Light';
  src: url('../public/fonts/bt-beau-sans/bt-beau-sans-light.woff') format('woff'),
    url('../public/fonts/bt-beau-sans/bt-beausans-light.woff2') format('woff2'),
    url('../public/fonts/bt-beau-sans/bt-beausans-light.eot') format('eot'),
    url('../public/fonts/bt-beau-sans/bt-beausans-light.svg') format('svg'),
    url('../public/fonts/bt-beau-sans/BT-BeauSans-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'RecoletaLight';
  src: url('../public/fonts/recoleta/recoleta-light.woff') format('woff'),
    url('../public/fonts/recoleta/recoleta-light.woff2') format('woff2'),
    url('../public/fonts/recoleta/recoleta-light.eot') format('eot'),
    url('../public/fonts/recoleta/recoleta-light.svg') format('svg'),
    url('../public/fonts/recoleta/recoleta-light.ttf') format('ttf');
}

@font-face {
  font-family: 'RecoletaRegular';
  src: url('../public/fonts/recoleta/recoleta-regular.woff') format('woff'),
    url('../public/fonts/recoleta/recoleta-regular.woff2') format('woff2'),
    url('../public/fonts/recoleta/recoleta-regular.eot') format('eot'),
    url('../public/fonts/recoleta/recoleta-regular.svg') format('svg'),
    url('../public/fonts/recoleta/recoleta-regular.ttf') format('ttf');
}

h1 {
  font-family: 'BBS Bold';
  font-size: 44px;
  line-height: 1.15em;
  color: #fff;
}

h2 {
  font-family: 'BBS Bold';
  font-size: 36px;
  line-height: 1.15em;
  color: #fff;
}

h3 {
  font-family: 'BBS Bold';
  font-size: 30px;
  line-height: 1.2em;
  color: #fff;
}

h4 {
  font-family: 'BBS Bold';
  font-size: 24px;
  line-height: 1.25em;
  color: #fff;
}

h5 {
  font-family: 'BBS Bold';
  font-size: 22px;
  line-height: 1.25em;
  color: #fff;
}

h6 {
  font-family: 'BBS Bold';
  font-size: 18px;
  line-height: 1.3em;
  color: #fff;
}

p {
  font-family: 'BBS Regular';
  font-size: 16px;
  line-height: 1.3em;
  color: #fff;
}

button {
  background-color: #3daed4;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'BBS Bold';
  padding: 8px 10px;
}

a {
  color: #fff;
  font-size: 16px;
  font-family: 'BBS Bold';
  text-decoration: underline;
}

label {
  font-family: 'BBS Medium';
  font-size: 16px;
  line-height: 1.3em;
  color: #fff;
}

input,
textarea,
select {
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  border-radius: 4px;
  padding: 8px 10px;
}

/* Tab bar css */
.tabBarBackground {
  padding-top: 10px;
  opacity: 1;
  background-image: linear-gradient(
    180deg,
    rgba(0, 33, 38, 0) 0%,
    rgba(0, 33, 38, 0.29) 16%,
    rgba(0, 33, 38, 0.49) 33%,
    rgba(0, 33, 38, 0.71) 48%,
    rgba(0, 33, 38, 0.92) 62%,
    rgba(0, 33, 38, 1) 100%
  );
  z-index: 1;
}

.tabBarIcon {
  background-color: transparent;
}

.tabBarIcon span {
  font-family: 'BBS Light';
}

.tabActive {
  opacity: 1;
}

.tabInactive {
  opacity: 0.5;
}

/* you css */

.acc-row {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.acc-row-last {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.top-row{
  border-top: none;
  margin-top: 25px;
}

/* reserve css */

.slot-full,
.slot-disabled {
  background-color: #002126;
  color: rgba(255, 255, 255, 0.5);
}

.slot-reserved {
  background-color: #3daed4;
  color: rgba(255, 255, 255, 1);
}

.slot-peak {
  color: #fff;
  background-color: #d4863d;
}

.highlight {
  color: #3daed4;
}

/* circularProgressBar css */

.circular-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.circular-progress {
  transform: rotate(0deg);
  width: 100%;
  height: 100%;
}

.circle-bg {
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 6px;
}

.circle {
  fill: none;
  stroke: #3daed4;
  stroke-width: 6;
  stroke-linecap: round;
  animation: progress 8s linear forwards;
}

@keyframes progress {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

/* string list with ticks css */

.fade-in-item {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fullSchedule {
  background-color: #9292924d;
}

.drawer {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  transition: bottom 0.3s ease-out;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
  z-index: 11111111111;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0s linear 0.2s; /* Delay visibility transition */
  z-index: 999; /* Ensure the overlay is above other content */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  opacity: 0;
  visibility: hidden;
  z-index: 999; /* Ensure the overlay is above other content */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: opacity 0.2s ease, visibility 0s linear 0.2s;
}

.popup-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.drawer-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.drawer-open {
  background-color: #002126;
  bottom: 0;
}

.drawer-content {
  padding: 20px;
}

.drawer button {
  margin-right: 10px;
}

button.redButton {
  background-color: #d53c3d;
}

button.confirm {
  background-color: #3daed4;
}

button.cancel,
button.blueBorder {
  border: 1px solid #3daed4;
  background-color: transparent;
}

.whiteBorder {
  border: 1px solid #ffffff54;
  background-color: transparent;
}

.clearBG {
  border: 1px solid #ffffff54;
  background-color: #ffffffac;
  color: #000000;
}

.reservation-item {
  transition: transform 0.5s ease;
}

.slide-up {
  transform: translateY(-115%);
}

.slide-up-heading {
  transform: translateY(-230%);
}

.hide-heading {
  display: none;
}

.packSelect > div {
  background-color: #1b373d;
  backdrop-filter: blur(8px);
  border: none;
}

.packSelect.subscription > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.loader {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 2px;
}
.loader:before {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}

.loader .loaderBar {
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: #3daed4;
  width: 0;
  animation: borealisBar 2s linear infinite;
}

.custom-option-label {
  position: relative;
  z-index: 1;
}

.custom-option-label img {
  position: absolute;
  top: 8px;
  left: 30px;
  width: 20px;
  height: 20px;
  z-index: 1000; /* Ensure this is higher than other elements */
  cursor: pointer;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

@keyframes snap {
  0%,
  49.99% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}

.snapIn {
  animation: snap 5s linear infinite;
}

.whiteShadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
